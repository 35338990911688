/* eslint-disable no-unused-vars */
import debounce from 'lodash.debounce';
import { Commit, MutationTree } from 'vuex';
import { getField, updateField } from 'vuex-map-fields';

import { createCircleMarkerHTMLElement, setMarkerColor } from '@/helpers/map/marker';

import ClickableMarker from '../helpers/map/ClickableMarker';
import {
  LineStringGeometry,
  MarkerOptions,
  PointGeometry,
  PrintRouteParameters,
  RouteParams,
  RoutingMethod,
  WaypointPlaces,
  WaypointTypes
} from '../helpers/types';
import ApiService from '../services/ApiService';
import { store } from '../store';
import { VehicleDimensions } from './app.module';

export type Address = {
  address: string;
  geometry: PointGeometry;
  id: number;
  type: WaypointTypes;
};

export type Tariff = {
  infrastructure: number;
  external: number;
  netTotal: number;
  vat: number;
  total: number;
};

export enum GuidanceType {
  start = 'start',
  waypoint = 'waypoint',
  destination = 'destination',
  keep_right = 'keep_right',
  turn_right = 'turn_right',
  keep_left = 'keep_left',
  turn_left = 'turn_left',
  turn_around = 'turn_around',
  highway_leave = 'highway_leave',
  priority_leave_straight = 'priority_leave_straight',
  priority_right = 'priority_right',
  priority_left = 'priority_left',
  roundabout_enter = 'roundabout_enter',
  roundabout_exit = 'roundabout_exit'
}

export type Guidance = {
  geometry: PointGeometry;
  distanceMeter: number;
  directionName: string;
  type: GuidanceType;
  roundAboutExit?: number;
};

export type RouteResult = {
  distanceMeter?: number;
  durationSecond?: number;
  geometry?: LineStringGeometry;
  paidPrice?: number;
  tariff?: Tariff;
  paidHighwayLength?: number;
  paidMotorwayLength?: number;
  legality?: [object];
  guidance?: Array<Guidance>;
  method?: RoutingMethod;
};

export type State = {
  selectedTruckEuroCategoryType?: string;
  selectedVehicleType?: string;
  selectedVehicleCategory?: string;
  selectedVehicleDimensions?: VehicleDimensions;
  maxWaypointsLength: number;
  waypointsList: Address[];
  newAddress?: Address;
  isAddressAutoCompleteLoading: boolean;
  addressAutoCompleteEntries?: any[];
  addressAutoCompleteEntry: Address | null;
  routeResult: Array<RouteResult>;
  routeNotFound?: boolean;
  waypointMarkers: ClickableMarker[];
  waypointId: number;
  waypointSearchResultMarker?: ClickableMarker;
  planningRouteIsInProgress: boolean;
  clickedMarker?: ClickableMarker;
  weight: number;
  axleWeight: number;
  height: number;
  width: number;
  length: number;
  useFerry: boolean;
  useMotorway: boolean;
  cursorOverMarker: boolean;
  planningPreferencesChanged: boolean;
  focusAutoComplete: boolean;
  planFromAutocomplete: boolean;
  routeParams?: RouteParams;
  lastAddressSearch: {
    searchText?: string;
    addressAutoCompleteEntries?: any[];
  };
};

const state: State = {
  selectedTruckEuroCategoryType: undefined,
  selectedVehicleType: undefined,
  selectedVehicleCategory: undefined,
  selectedVehicleDimensions: undefined,
  maxWaypointsLength: 10,
  waypointsList: [],
  newAddress: undefined,
  isAddressAutoCompleteLoading: false,
  addressAutoCompleteEntries: [],
  addressAutoCompleteEntry: null,
  routeResult: [],
  routeNotFound: undefined,
  waypointMarkers: [],
  waypointId: 0,
  waypointSearchResultMarker: undefined,
  planningRouteIsInProgress: false,
  clickedMarker: undefined,
  weight: 0,
  axleWeight: 0,
  height: 0,
  width: 0,
  length: 0,
  useFerry: false,
  useMotorway: true,
  cursorOverMarker: false,
  planningPreferencesChanged: false,
  focusAutoComplete: false,
  planFromAutocomplete: false,
  routeParams: undefined,
  lastAddressSearch: {
    searchText: undefined,
    addressAutoCompleteEntries: []
  }
};

function setToDefaultValue(defaultValue: number, inputValue: number) {
  let result = inputValue;
  if (!inputValue || inputValue < defaultValue) {
    result = defaultValue;
  }

  return result;
}

const actions = {
  setPlanFromAutocomplete({ commit }: { commit: Commit }, planFromAutocomplete: boolean) {
    commit('setPlanFromAutocomplete', planFromAutocomplete);
  },

  setAddressAutoCompleteEntries(
    { commit }: { commit: Commit },
    addressAutoCompleteEntriesList: any[]
  ) {
    commit('setAddressAutoCompleteEntries', addressAutoCompleteEntriesList);
  },

  refreshAddressEntries: debounce(async function ({ commit }: any, value: string) {
    commit('setIsAddressAutoCompleteLoading', true);
    try {
      value = value.trim();
      const result: any = [];
      if (value.length >= 2) {
        const response = await ApiService.locationSearch(value);
        response.data.result.features.forEach((item: any) => {
          result.push({
            address: item.properties.address,
            geometry: item.geometry
          });
        });
        commit('setLastAddressSearch', { searchText: value, addressAutoCompleteEntries: result });
      }

      commit('setAddressAutoCompleteEntries', result);
    } catch (ex) {
      commit('setAddressAutoCompleteEntries', []);
    } finally {
      commit('setIsAddressAutoCompleteLoading', false);
    }
  }, 300),

  async planRoute({ commit, state, dispatch }: { commit: any; state: any; dispatch: any }) {
    // plan route only if there are at least 2 waypoints
    if (state.waypointsList.length > 1) {
      commit('setPlanningRouteIsInProgress', true);

      commit('setRouteParams', {
        vehicleType: state.selectedVehicleType,
        vehicleCategory: state.selectedVehicleCategory,
        euroCategory: state.selectedTruckEuroCategoryType,
        weight: state.weight,
        axleWeight: state.axleWeight,
        height: state.height,
        width: state.width,
        length: state.length,
        useFallback: true,
        guidance: true,
        ferry: state.useFerry,
        motorway: state.useMotorway,
        waypoints: state.waypointsList.map(
          (waypoint: { geometry: { coordinates: any } }) => waypoint.geometry?.coordinates
        )
      });

      try {
        // call routing api with each routing method to get alternatives
        const { data } = await ApiService.routePlan(state.routeParams);

        if (data.length === 0) {
          commit('setRouteNotFound', true);
        } else {
          commit('setRouteResult', data);
        }
        commit('setPlanningPreferencesChanged', false);
      } catch (err: any) {
        dispatch('alert/error', err.response.data.message ?? err.message ?? err, { root: true });
      } finally {
        commit('setPlanningRouteIsInProgress', false);
        if (state.planFromAutocomplete) {
          commit('changeFocusAutocomplete');
          commit('setPlanFromAutocomplete', false);
        }
      }
    }
  },

  addToWaypointsList(
    { commit, dispatch, state, rootState }: any,
    payload: {
      waypoint: { id: any; type: WaypointTypes; place: WaypointPlaces };
      center: any;
      map: any;
      before: number;
    }
  ) {
    if (state.waypointsList.length < state.maxWaypointsLength) {
      const waypointId = state.waypointId;
      if (payload.before !== undefined) {
        commit('addWaypointToWaypointsListBefore', {
          waypoint: payload.waypoint,
          before: payload.before
        });
      } else {
        commit('addWaypointToWaypointsList', payload.waypoint);
      }

      const markerOptions: MarkerOptions = {
        color: rootState.app.viewConfig.theme.waypoint[payload.waypoint.place],
        scale: 0.9,
        draggable: true,
        type: payload.waypoint.type
      };

      if (payload.waypoint.type === WaypointTypes.SOFT) {
        markerOptions.element = createCircleMarkerHTMLElement();
      }

      const newWaypointMarker = new ClickableMarker(
        markerOptions,
        payload.waypoint.id || waypointId
      )
        .setLngLat(payload.center)
        .addTo(payload.map);

      dispatch('addContextmenuToMarker', {
        marker: newWaypointMarker,
        map: payload.map
      });

      newWaypointMarker.getElement().addEventListener('mousedown', (e: any) => {
        // enable dragging marker only with left click
        if (e.button === 0) {
          newWaypointMarker.setDraggable(true);
        } else {
          newWaypointMarker.setDraggable(false);
        }
      });

      dispatch('addDragendToMarker', {
        marker: newWaypointMarker,
        map: payload.map,
        waypoint: payload.waypoint
      });

      newWaypointMarker.getElement().addEventListener('mouseenter', () => {
        state.cursorOverMarker = true;
      });

      newWaypointMarker.getElement().addEventListener('mouseleave', () => {
        state.cursorOverMarker = false;
      });

      if (payload.before !== undefined) {
        commit('addMarkerToWaypointMarkersBefore', {
          marker: newWaypointMarker,
          before: payload.before
        });
      } else {
        commit('addMarkerToWaypointMarkers', newWaypointMarker);
      }
      dispatch('updateWaypointColors');
      dispatch('planRoute');
    }
  },

  addDragendToMarker(
    { commit, dispatch, state }: any,
    payload: {
      marker: {
        on: (arg0: string, arg1: (e: any) => Promise<void>) => void;
        setLngLat: (arg0: any) => void;
        getLngLat: () => { (): any; new (): any; lng: number; lat: number };
      };
      map: { queryRenderedFeatures: (arg0: any[][]) => any };
      waypoint: { id: any; type: WaypointTypes };
    }
  ) {
    payload.marker.on('dragend', async (e: any) => {
      try {
        let newWaypoint = {};

        const reverseGeocodeRes = await ApiService.reverseGeocode(e.target._lngLat);
        if (
          !reverseGeocodeRes.data.result.features ||
          reverseGeocodeRes.data.result.features.length === 0
        ) {
          throw new Error();
        }

        // update waypoint with newly geocoded result
        const waypoint: Address = {
          id: payload.waypoint.id,
          address: reverseGeocodeRes.data.result.features[0].properties.address,
          geometry: {
            type: 'Point',
            coordinates: [payload.marker.getLngLat().lng, payload.marker.getLngLat().lat]
          },
          type: payload.waypoint.type
        };

        newWaypoint = waypoint;

        const index = state.waypointsList.findIndex(
          (waypoint: { id: any }) => waypoint.id === payload.waypoint.id
        );
        commit('updateWaypoint', { index, newWaypoint });
        dispatch('planRoute');
      } catch (err) {
        // set marker back to original coordinates at error
        const waypoint = state.waypointsList.find(
          (waypoint: { id: any }) => waypoint.id === payload.waypoint.id
        );
        payload.marker.setLngLat(waypoint?.geometry.coordinates);
      }
    });
  },

  addContextmenuToMarker(
    { commit }: any,
    payload: {
      marker: {
        getElement: () => {
          (): any;
          new (): any;
          addEventListener: {
            (arg0: string, arg1: (e: any) => void): void;
            new (): any;
          };
        };
      };
      map: { dragRotate: { disable: () => void; enable: () => void } };
    }
  ) {
    payload.marker.getElement().addEventListener('contextmenu', (e: any) => {
      payload.map.dragRotate.disable();
      commit('setClickedMarker', payload.marker);

      e.preventDefault();
      e.stopPropagation();
      payload.map.dragRotate.enable();
    });
  },

  initRoutingData({ commit, dispatch }: { commit: any; dispatch: any }) {
    const vehicleTypes = store.state.app.vehicleTypes;
    commit('setSelectedVehicleType', vehicleTypes[0].value);
    const vehicleCategories = store.state.app.vehicleCategories;
    commit('setSelectedVehicleCategory', vehicleCategories[0]);
    const euroCategories = store.state.app.truckEuroCategoryTypes;
    commit('setSelectedTruckEuroCategoryType', euroCategories[0].value);
    dispatch('setVehicleDimensions', vehicleTypes[0].value);
  },

  deleteFromWaypointsList({ commit, dispatch }: any, id: any) {
    commit('deleteFromWaypointsList', id);
    dispatch('updateWaypointColors');
    dispatch('planRoute');
  },

  updateWaypointColors({ commit, rootState }: any) {
    for (let idx = 0; idx < state.waypointMarkers.length; idx++) {
      const marker = state.waypointMarkers[idx];
      if (marker.type === WaypointTypes.HARD) {
        let color;
        if (idx === 0 || idx === state.waypointMarkers.length - 1) {
          color = rootState.app.viewConfig.theme.waypoint[WaypointPlaces.END];
        } else {
          color = rootState.app.viewConfig.theme.waypoint[WaypointPlaces.MID];
        }
        commit('updateWaypointColor', { marker, color });
      }
    }
  },

  sortWaypointMarkers({ commit, dispatch }: { commit: any; dispatch: any }) {
    commit('sortWaypointMarkers');
    dispatch('updateWaypointColors');
  },

  // #region - routing params setters
  setVehicleType({ commit }: any, vehicleType: string) {
    commit('setSelectedVehicleType', vehicleType);
    commit('setPlanningPreferencesChanged', true);
  },
  setVehicleCategory({ commit }: any, vehicleCategory: string) {
    commit('setSelectedVehicleCategory', vehicleCategory);
    commit('setPlanningPreferencesChanged', true);
  },
  setTruckEuroCategoryType({ commit }: any, euroCategory: string) {
    commit('setSelectedTruckEuroCategoryType', euroCategory);
    commit('setPlanningPreferencesChanged', true);
  },
  setWeight({ commit }: any, weight: number) {
    commit('setWeight', weight);
    commit('setPlanningPreferencesChanged', true);
    commit('setRouteResult', []);
  },
  setAxleWeight({ commit }: any, axleWeight: number) {
    commit('setAxleWeight', axleWeight);
    commit('setPlanningPreferencesChanged', true);
    commit('setRouteResult', []);
  },
  setHeight({ commit }: any, height: number) {
    commit('setHeight', height);
    commit('setPlanningPreferencesChanged', true);
    commit('setRouteResult', []);
  },
  setWidth({ commit }: any, width: number) {
    commit('setWidth', width);
    commit('setPlanningPreferencesChanged', true);
    commit('setRouteResult', []);
  },
  setLength({ commit }: any, length: number) {
    commit('setLength', length);
    commit('setPlanningPreferencesChanged', true);
    commit('setRouteResult', []);
  },
  setFerry({ commit }: any, ferry: boolean) {
    commit('setUseFerry', ferry);
    commit('setPlanningPreferencesChanged', true);
  },
  setMotorway({ commit }: any, motorway: boolean) {
    commit('setUseMotorway', motorway);
    commit('setPlanningPreferencesChanged', true);
  },
  setVehicleDimensions({ commit, rootState }: any, vehicleType: string) {
    commit('setSelectedVehicleDimensions', rootState.app.vehiclesDimensions[vehicleType]);
  }
  // #endregion - routing params setters
};

const mutations: MutationTree<State> = {
  setPlanningRouteIsInProgress(state, planningRouteIsInProgress: boolean) {
    state.planningRouteIsInProgress = planningRouteIsInProgress;
  },

  setPlanFromAutocomplete(state, planFromAutocomplete: boolean) {
    state.planFromAutocomplete = planFromAutocomplete;
  },

  changeFocusAutocomplete(state) {
    state.focusAutoComplete = !state.focusAutoComplete;
  },

  setIsAddressAutoCompleteLoading(state: { isAddressAutoCompleteLoading: any }, payload: any) {
    state.isAddressAutoCompleteLoading = payload;
  },

  setAddressAutoCompleteEntries(state, payload: any) {
    state.addressAutoCompleteEntries = payload;
  },

  setLastAddressSearch(state, payload: any) {
    state.lastAddressSearch = payload;
  },

  addWaypointToWaypointsList(state, payload) {
    payload.id = state.waypointId;
    state.waypointId++;

    state.waypointsList.push(payload);
  },

  addWaypointToWaypointsListBefore(state, payload) {
    payload.waypoint.id = state.waypointId;
    state.waypointId++;
    state.waypointsList.splice(payload.before, 0, payload.waypoint);
  },

  updateWaypoint(state, payload: { index: any; newWaypoint: Address }) {
    state.waypointsList.splice(payload.index, 1, payload.newWaypoint as Address);
  },

  addMarkerToWaypointMarkers(state, payload: any) {
    state.waypointMarkers.push(payload);
  },

  addMarkerToWaypointMarkersBefore(state, payload: any) {
    state.waypointMarkers.splice(payload.before, 0, payload.marker);
  },

  setClickedMarker(state, payload: any) {
    state.clickedMarker = payload;
  },

  deleteFromWaypointsList(state, id: any) {
    const index = state.waypointsList.findIndex((waypoint: { id: any }) => waypoint.id === id);
    const marker = state.waypointMarkers.find((marker: { id: any }) => marker.id === id);
    if (marker) {
      state.waypointMarkers = state.waypointMarkers.filter(
        (marker: { id: any }) => marker.id !== id
      );
      marker.remove();
    }

    state.waypointsList.splice(index, 1);
  },

  sortWaypointMarkers(state) {
    const sortArray = state.waypointsList.map((marker) => marker.id);
    // eslint-disable-next-line id-length
    state.waypointMarkers.sort((a, b) => sortArray.indexOf(a.id) - sortArray.indexOf(b.id));
  },

  updateWaypointColor(state, payload) {
    setMarkerColor(payload.marker, payload.color);
  },

  setRouteParams(state, payload: RouteParams) {
    state.routeParams = payload;
  },

  setRouteNotFound(state, routeNotFound: boolean) {
    state.routeNotFound = routeNotFound;
  },

  setRouteResult(
    state,
    payload: Array<
      RouteResult & {
        error?: any;
      }
    >
  ) {
    state.routeResult = [];
    for (const route of payload) {
      // if route found
      const routeResult: RouteResult = {};
      Object.assign(routeResult, route);

      state.routeResult.push(routeResult);

      state.routeNotFound = false;
    }
  },

  clearClickedMarker(state) {
    state.clickedMarker = undefined;
  },

  // #region - routing params setters
  setSelectedVehicleType(state, vehicleType: string) {
    state.selectedVehicleType = vehicleType;
  },
  setSelectedVehicleCategory(state, vehicleCategory: string) {
    state.selectedVehicleCategory = vehicleCategory;
  },
  setSelectedTruckEuroCategoryType(state, euroCategory: string) {
    state.selectedTruckEuroCategoryType = euroCategory;
  },
  setWeight(state, weight: number) {
    state.weight = weight;
  },
  setAxleWeight(state, axleWeight: number) {
    axleWeight = setToDefaultValue(0, axleWeight);
    state.axleWeight = axleWeight;
  },
  setHeight(state, height: number) {
    height = setToDefaultValue(0, height);
    state.height = height;
  },
  setWidth(state, width: number) {
    width = setToDefaultValue(0, width);
    state.width = width;
  },
  setLength(state, length: number) {
    length = setToDefaultValue(0, length);
    state.length = length;
  },
  setUseFerry(state, useFerry: boolean) {
    state.useFerry = useFerry;
  },
  setUseMotorway(state, useMotorway: boolean) {
    state.useMotorway = useMotorway;
  },
  setSelectedVehicleDimensions(state, vehicleDimensions: VehicleDimensions) {
    state.selectedVehicleDimensions = vehicleDimensions;
  },

  // #endregion - routing params setters

  setPlanningPreferencesChanged(state, changed: boolean) {
    state.planningPreferencesChanged = changed;
  },

  updateField
};

const getters = {
  clickedMarker: (state: State) => state.clickedMarker,
  isAddressAutoCompleteLoading: (state: State) => state.isAddressAutoCompleteLoading,
  lastAddressSearch: (state: State) => state.lastAddressSearch,
  maxWaypointsLength: (state: State) => state.maxWaypointsLength,
  newAddress: (state: State) => state.newAddress,
  routeNotFound: (state: State) => state.routeNotFound,
  printRouteParams: (state: State): PrintRouteParameters => ({
    vehicleType: state.selectedVehicleType,
    vehicleCategory: state.selectedVehicleCategory,
    euroCategory: state.selectedTruckEuroCategoryType,
    weight: state.weight,
    axleWeight: state.axleWeight,
    height: state.height,
    width: state.width,
    length: state.length,
    ferry: state.useFerry,
    motorway: state.useMotorway
  }),
  routeParams: (state: State) => state.routeParams,
  routeResult: (state: State) => state.routeResult,
  waypointsList: (state: State) => state.waypointsList,
  waypointMarkers: (state: State) => state.waypointMarkers,
  waypointListNotFull: (state: State) => state.waypointsList.length < state.maxWaypointsLength,
  getField
};

export const routePlanner = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
